/**
 * Flexslider init
 *
 */
(function($) {

    /**
     * Lazy load flexslider
     * https://web-design-weekly.com/snippets/lazy-loading-images-flexslider/
     */
    $(window).load(function() {
        $('.js-slider').flexslider({
            namespace: 'c-slider__',
            touch: true,
            controlNav: true,
            animation: "fade",
            animationLoop: true,
            animationSpeed: 0,
            slideshow: false,
            slideshowSpeed: 7000,
            initDelay: 0,
            start: function(slider) { // Fires when the slider loads the first slide
                
                // Loading.
                setTimeout(function() {
                    slider.removeClass('is-loading');
                }, 1000);
                
                
                // Lazy load.
                $(slider)
                .find('img.lazy:eq(0)')
                .each(function() {
                    var src = $(this).attr('data-src');
                    $(this).attr('src', src).removeAttr('data-src');
                });
                
                // Customise direction nav.
                slider.find('.c-slider__next')
                .text('')
                .html('<i class="c-icon c-icon--chevron-right u-text-contrast"></i>');
                
                slider.find('.c-slider__prev')
                .text('')
                .html('<i class="c-icon c-icon--chevron-left u-text-contrast"></i>');
                
                
                // Set-up slide count.
				slider.append('<div class="c-slider__count u-text-contrast"><i class="o-icon u-icon-camera"></i> <span class="c-slider__count-current"></span> <span> / </span> <span class="c-slider__count-total"></span></div>');
	        	slider.find('.c-slider__count-current').text(slider.currentSlide+1);
	        	slider.find('.c-slider__count-total').text(slider.count);
            },
	        after: function(slider) {
    	        
	        	// Update slide count.
	        	slider.find('.c-slider__count-current').text(slider.currentSlide+1);
	        	slider.find('.c-slider__count-total').text(slider.count);
	        	
	        },
            before: function(slider) { // Fires asynchronously with each slider animation
                var slides     = slider.slides,
                index      = slider.animatingTo,
                $slide     = $(slides[index]),
                $img       = $slide.find('img[data-src]'),
                current    = index,
                next_slide  = current + 1,
                prev_slide = current - 1;
                
                $slide
                .parent()
                .find('img.lazy:eq(' + current + '), img.lazy:eq(' + prev_slide + '), img.lazy:eq(' + next_slide + ')')
                .each(function() {
                    var src = $(this).attr('data-src');
                    $(this).attr('src', src).removeAttr('data-src');
                });
            }
        });
    });

})(jQuery);