/**
 * Flexslider init
 *
 */
(function($) {
  
  
    // fix chrome jumping background
      // http://stackoverflow.com/questions/24944925/background-image-jumps-when-address-bar-hides-ios-android-mobile-chrome
      var bg = $(".js-hero, .js-hero .c-hero__item");

      function resizeBackground() {
          bg.height($(window).height() + 60 - 48); // height plus 60 (chrome nav height) minus 48 (header height)
      }
      
      $(window).on('breakpoint-change', function(e, breakpoint) {
        if(breakpoint === 'portable') {
          resizeBackground();
        } else {
          bg.css("height", "");
        }
      });
  
  
  
    
    /**
     * Lazy load flexslider
     * https://web-design-weekly.com/snippets/lazy-loading-images-flexslider/
     */
    $(window).load(function() {
      
      $('.js-hero').flexslider({
          namespace: 'c-hero__',
          touch: true,
          controlNav: false,
          directionNav: false,
          animation: "fade",
          animationLoop: true,
          animationSpeed: 0,
          slideshow: true,
          slideshowSpeed: 6000,
          initDelay: 0,
          start: function(slider) { // Fires when the slider loads the first slide
              
              // Loading.
              setTimeout(function() {
                  slider.removeClass('is-loading');
              }, 1000);
              
              
              // Lazy load.
              $(slider)
              .find('img.lazy:eq(0)')
              .each(function() {
                  var src = $(this).attr('data-src');
                  $(this).attr('src', src).removeAttr('data-src');
              });
              
              
              // Set-up slide count.
			slider.append('<div class="c-hero__count u-text-contrast"><i class="o-icon u-icon-camera"></i> <span class="c-hero__count-current"></span> <span> / </span> <span class="c-hero__count-total"></span></div>');
        	slider.find('.c-hero__count-current').text(slider.currentSlide+1);
        	slider.find('.c-hero__count-total').text(slider.count);
          },
        after: function(slider) {
  	        
        	// Update slide count.
        	slider.find('.c-hero__count-current').text(slider.currentSlide+1);
        	slider.find('.c-hero__count-total').text(slider.count);
        	
        },
          before: function(slider) { // Fires asynchronously with each slider animation
              var slides     = slider.slides,
              index      = slider.animatingTo,
              $slide     = $(slides[index]),
              $img       = $slide.find('img[data-src]'),
              current    = index,
              next_slide  = current + 1,
              prev_slide = current - 1;
              
              $slide
              .parent()
              .find('img.lazy:eq(' + current + '), img.lazy:eq(' + prev_slide + '), img.lazy:eq(' + next_slide + ')')
              .each(function() {
                  var src = $(this).attr('data-src');
                  $(this).attr('src', src).removeAttr('data-src');
              });
          }
      });
    });
      
    
})(jQuery);