/**
 * Carousel init
 *
 */
(function($) {

  // store the slider in a local variable
  var $window = $(window),
      flexslider = { vars:{} };
 
  // tiny helper function to add breakpoints
  function getGridSize() {
    return (window.innerWidth < 480) ? 1 :
           (window.innerWidth < 900) ? 2 : 3;
  }
 
  $window.load(function() {
    $('.js-carousel').flexslider({
      namespace: 'c-carousel__',
      animation: "slide",
      animationLoop: false,
      animationSpeed: 400,
      slideshow: false,
      //useCSS: false,
      itemWidth: 210,
      itemMargin: 2,
      minItems: getGridSize(), // use function to pull in initial value
      maxItems: getGridSize(), // use function to pull in initial value
      start: function(slider) { // Fires when the slider loads the first slide
        // Customise direction nav.
        slider.find('.c-carousel__next')
              .text('')
              .html('<i class="c-icon c-icon--chevron-right-2"></i>');
        slider.find('.c-carousel__prev')
              .text('')
              .html('<i class="c-icon c-icon--chevron-left-2"></i>');
      }
    });
  });
 
  // check grid size on resize event
  $window.resize(function() {
    var gridSize = getGridSize();
    flexslider.vars.minItems = gridSize;
    flexslider.vars.maxItems = gridSize;
  });

})(jQuery);