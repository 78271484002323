/* ==========================================================================
   #MAP
   ========================================================================== */

/**
 * Conditional loading map.
 * http://adactio.com/journal/5414/
 */

(function($) {

  var $map = $('.js-map');

  if ( $map.length > 0) {

    $(window).on('breakpoint-change', function(e, breakpoint) {

      if ($map.is(':empty')){
        var $src = $map.attr('data-src');
        var $class = $map.attr('data-class');
        $map.append('<div class="[ o-ratio o-ratio--16:9 ] c-map mb4 [ is-loading u-loading-tint ] '+$class+'"><iframe class="o-ratio__content c-map__iframe" style="border: 0;" src="'+$src+'" width="100%" height="500" frameborder="0" allowfullscreen="allowfullscreen"></iframe></div>');

        // Loading.
        setTimeout(function() {
          $('.c-map').removeClass('is-loading');
        }, 1000);
      }
    });

  }

})(jQuery);