/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // init fitvids
        $('.c-site-main').fitVids();

        // init packery
        var $grid = $('.js-grid').imagesLoaded( function() {
            $grid.packery({
                itemSelector: '.c-grid__item'
            }).addClass('is-loaded').removeClass('is-loading');
        });

        /**
         * Parallax image.
         * Inspired by: http://codepen.io/zitrusfrisch/pen/bJwhk
         * Scroll in viewport: http://stackoverflow.com/questions/11032373/animate-a-div-with-jquery-when-we-scroll-to-its-position
         */
        function EasyPeasyParallax() {
            scrollPos = $(this).scrollTop();
            $('.js-parallax > .c-hero__image').css({
              'transform': 'translate3d(0px, ' + (scrollPos/12) + 'px, 0px)'
            });
            $('.js-parallax > .c-hero__content').css({
              //'transform': 'translate3d(0px, ' + (scrollPos/10) + 'px, 0px)',
              //'opacity': 1-(scrollPos/250)
            });
          }
          $(window).scroll(function() {
            //EasyPeasyParallax();
          });
          
          
          
          /**
           * Need to clean up
           */
          $(window).scroll(function(e) {
            
            $('.js-parallax').each(function(){
              var y = $(window).scrollTop();
              var h = $(window).height();
              var t = $(this);
              var tPos = t.position().top;
              scrollPos = y;
              var img = t.find('.c-hero__image');
          
              if(y + h < t.position().top) {
                  //t.html('not in viewport').css('background-color', 'red');
              //} else if(y + h < t.position().top + t.outerHeight()) {
                  //t.html('partially in viewport').css('background-color', 'yellow');
              } else if(y > t.position().top + t.outerHeight()) {
                  //t.html('not in viewport').css('background-color', 'red');
                  
              //} else if(y > t.position().top) {
                  //t.html('partially in viewport').css('background-color', 'yellow');
              } else {
                //t.html('in viewport').css('background-color', 'green');
                //t.html( (y + h) - t.position().top );
                img.css({
                  'transform': 'translate3d(0px, ' + (scrollPos/10) + 'px, 0px)'
                });
              }
            });
        });
          
          

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
