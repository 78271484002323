(function($) {
    
    
    $('.js-nav-mob-toggle').on("click touchstart",function(e){
        $('html').toggleClass("is-nav-mob-active");
            e.preventDefault();
    });
    

    /**
     * HOVERINTENT INIT
     *
     * Add hoverIntent.js support to dropdown menus only on screen size 769px and up.
     *
     * Dependencies: jquery.hoverIntent
     *
     * 1. Jquery unbind/rebind hoverIntent: http://stackoverflow.com/questions/8151278/
     */
    var $dropdownMenus = $('.js-hover-intent .has-sub-menu');
    
    function navRollOver(e){
        $(this).addClass('is-open').find('> ul').show();
    }
    function navRollOut(e){
        $(this).removeClass('is-open').find('> ul').hide();
    }
    
    if ( $dropdownMenus.length > 0) {
        $(window).on('breakpoint-change', function(e, breakpoint) {
            if(breakpoint === 'desk') {
                // bind hoverintent on desk.
                $dropdownMenus.hoverIntent( navRollOver, navRollOut );
                
            } else {
                // unbind hover intent on portable.
                $dropdownMenus.unbind("mouseenter").unbind("mouseleave");
                $dropdownMenus.removeProp('hoverIntent_t');
                $dropdownMenus.removeProp('hoverIntent_s');
            }
        });
    }
    
    
    
    
    
    /**
	 * MOBILE NAV
	 *
	 * Add open/close functionality to mobile site-nav.
	 */
	var $siteNav = $('.js-site-nav');
	var $siteNavLink = $siteNav.find('.has-sub-menu > a');
		
    if ( $siteNav.length > 0) {
        $(window).on('breakpoint-change', function(e, breakpoint) {
            if(breakpoint === 'desk') {
                
                // if parent page, expand current nav
                $siteNav.find('.is-open').find('> ul').hide();
                $siteNavLink.unbind("click");
                
            } else {
                
                // if parent page, expand current nav
                $siteNav.find('.is-active').addClass('is-open').find('> ul').show();
    			
    			$siteNavLink.unbind("click");
    			
    			$siteNavLink.bind("click",function(e){
    				e.preventDefault();
    				
    				var $this = $(this);
    				var $siteNavItem = $this.parent();
    				var $siteNavDropdown = $(this).next('ul');
    				var $allMenuItems = $siteNav.find('.has-sub-menu');
    				var $allMenuDropdowns = $siteNav.find('.has-sub-menu ul');
    				var $easingMethod = "easeInOutCubic";//easeInOutCubic
    				var $speed	= 300;
    				
    				if ( $siteNavItem.hasClass('is-open') ) {
        				$allMenuItems.removeClass('is-open');
        				//$allMenuDropdowns.hide();
        				$allMenuDropdowns.slideUp({
        					duration: $speed,
        					easing: $easingMethod
        				});
        				
        			} else {
        				$allMenuItems.removeClass('is-open');
        				//$allMenuDropdowns.hide();
        				$allMenuDropdowns.slideUp({
        					duration: $speed,
        					easing: $easingMethod
        				});
        				$siteNavItem.addClass('is-open');
        				//$siteNavDropdown.show();
        				$siteNavDropdown.slideDown({
        					duration: $speed,
        					easing: $easingMethod
        				});
        				
        				/*
        				setTimeout(function() {
                            $('.offcanvas__nav').animate({
                                scrollTop: $( $siteNavItem ).offset().top
                            }, $speed);
                        }, $speed, $easingMethod);
        				*/
                    }
                });
            }
        });   
    }
		
		
		
	
    
    


})(jQuery);