/**
 * Breakpoints.js
 * Detecting media queries with Javascript
 * http://zerosixthree.se/detecting-media-queries-with-javascript/
 */ 

(function($) {
    var breakpoint = {};
    breakpoint.refreshValue = function () {
      this.value = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/\"/g, '');
    };
    
    $(window).resize(function () {
      breakpoint.refreshValue();
    }).resize();
    
    if (breakpoint.value === 'portable') {
      console.log('Portable breakpoint');
    }
    else {
      console.log('Some other breakpoint');
    }
})(jQuery);


//'use strict';
var Z63 = (function (parent, $) {

    var MediaQueryListener = function() {
        this.afterElement = window.getComputedStyle ? window.getComputedStyle(document.body, ':after') : false;
        this.currentBreakpoint = '';
        this.lastBreakpoint = '';
        this.init();
    };

    MediaQueryListener.prototype = {

        init: function () {
            var self = this;
            
            if(!self.afterElement) {
                return;
            }

            self._resizeListener();

        },
        _resizeListener: function () {
            var self = this;

            $(window).on('resize orientationchange load', function() {
                // Regexp for removing quotes added by various browsers
                self.currentBreakpoint = self.afterElement.getPropertyValue('content').replace(/^["']|["']$/g, '');
                
                if (self.currentBreakpoint !== self.lastBreakpoint) {
                    $(window).trigger('breakpoint-change', self.currentBreakpoint);
                    self.lastBreakpoint = self.currentBreakpoint;
                }
                
            });
        }

    };

    parent.mediaqueryListener = parent.mediaqueryListener || new MediaQueryListener();

    return parent;

}(Z63 || {}, jQuery));

/*
(function($) {
    $(window).on('breakpoint-change', function(e, breakpoint) {
    
        if(breakpoint === 'portable') {
            //document.body.innerHTML = 'CSS Breakpoint <span>portable</span>';
        }
    
        if(breakpoint === 'desk') {
            //document.body.innerHTML = 'CSS Breakpoint desk';
        }
    
    });
})(jQuery);*/