/**
 * Smooth Scroll
 *
 * Usage: use .js-scroll class to bind scroll to a link
 * or to all child links.
 *
 * Example 1:
 *
    <div class="js-scroll">
        <a href="#">link</a>
    </div>
 *
 * Example 2:
 *
    <a href="#" class="js-scroll">link</a>
 *
 */

(function($) {

  //var headerHeight = $(".c-site-head").height();
  $(window).on('breakpoint-change', function(e, breakpoint) {
    if(breakpoint === 'desk') {
      headerHeight = $(".c-site-head").outerHeight();
    } else {
      headerHeight = $(".c-site-head").outerHeight();
    }
  });

  $('.js-scroll a[href*=\\#], .js-scroll[href*=\\#]').click(function() {
    if( ! $(this).hasClass('js-no-scroll') ) {
      if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
        var $target = $(this.hash);
        $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
        if ($target.length) {
          var targetOffset = $target.offset().top - headerHeight;
          $('html,body')
          .animate({scrollTop: targetOffset}, 500);
          return false;
        }
      }
    }
  });

})(jQuery);