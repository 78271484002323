/* ==========================================================================
   #JS-VIDEO
   ========================================================================== */

(function($) {

  $('.js-video').click(function(){

    video = '<iframe src="'+ $(this).attr('data-video') +'" width="1024" height="576" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

    $(this).replaceWith(video);

  });

})(jQuery);