/*------------------------------------*\
	#ACCORDION
\*------------------------------------*/

/**
 * Author: Matt Branthwaite
 * Version: 1.1.2
 * Usage: add class 'js-accordion'
 */

(function($) {	

	/* ---- Accordion init ---- */
	if ($('.js-accordion').length > 0) {		
		
		// Accordion set-up
		$('.js-accordion').each(function(){
		
			var $this = $(this);
			
			// 1. Set-up all panels
			$accordionHead = $this.children().first().prop('tagName');
			
			//alert($accordionHead);
			
			
			$this.find('> ' + $accordionHead).each(function(){ 
				$(this).addClass('c-accordion__head');
			});
			$this.find('.c-accordion__head').each(function(){ 
				var $content = $(this).nextUntil(".c-accordion__head");
				$content.wrapAll('<div class="c-accordion__body" />');
				var $panel = $(this).nextUntil(".c-accordion__head").addBack();
				$panel.wrapAll('<div class="c-accordion__item" />');
			});
			
			//$this.find('.c-accordion__item:last-child').addClass('last');
			//$this.find('.c-accordion__head').append('<i class="c-accordion__icon">+</i>');
			$this.find('.c-accordion__body').hide();
			$this.find('.c-accordion__body').wrapInner('<div class="c-accordion__content"></div>');
		});
		
		
		// Click event
		$('.c-accordion__head').click(function(){
			var $speed	= 300;
			var $accordion	= $(this).parent().parent();
			var $panel = $(this).parent();
			var $trigger = $(this);
			var $content = $(this).next('.c-accordion__body');
			var $allPanels = $accordion.find('.c-accordion__item');
			var $allContent = $accordion.find('.c-accordion__body');
			var $easing = "easeInOutCubic";
			
			if ( $panel.hasClass('is-open') ) {
				$allPanels.removeClass('is-open');
				//$accordion.find('.c-accordion__icon').text('+');
				$allContent.slideUp({
					duration: $speed,
					easing: $easing
				});
				
			} else {
				$allPanels.removeClass('is-open');
				//$accordion.find('.c-accordion__icon').text('+');
				$allContent.slideUp({
					duration: $speed,
					easing: $easing
				});
				$panel.addClass('is-open');
				$panel.find('.c-accordion__icon').text('–');
				$content.slideDown({
					duration: $speed,
					easing: $easing
				});
				
				// http://stackoverflow.com/questions/6677035/jquery-scroll-to-element
				/*
				setTimeout(function() {
                    $offset = $panel.offset();
                    $('html, body').animate({
                        scrollTop: $offset.top - 90
                    }, $speed, $easing);
                }, $speed);*/
                // test
                //$panel.prepend( "left: " + $offset.left + ", top: " + $offset.top );
			}
		});
	}
	
})(jQuery);